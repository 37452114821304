'use client'

import { createContext, useContext, useState, useEffect } from "react";

export interface CartItem {
  _id: string;
  name: string;
  price: number;
  image: string;
  brand?: string;
  stripePriceId?: string;
  quantity?: number;
  sku?: string;
}

interface CartContextType {
  cartItems: CartItem[];
  addToCart: (item: CartItem) => { success: boolean; message: string };
  removeFromCart: (index: number) => void;
  isItemInCart: (itemId: string) => boolean;
}

export const CartContext = createContext<CartContextType | undefined>(undefined);

export function CartProvider({ children }: { children: React.ReactNode }) {
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    const localData = localStorage.getItem("cart");
    if (localData) {
      try {
        setCartItems(JSON.parse(localData));
      } catch (e) {
        console.error("Error parsing cart data:", e);
        setCartItems([]);
      }
    }
  }, []);

  useEffect(() => {
    if (mounted) {
      localStorage.setItem("cart", JSON.stringify(cartItems));
    }
  }, [cartItems, mounted]);

  const isItemInCart = (itemId: string) => {
    return cartItems.some(item => item._id === itemId);
  };

  const addToCart = (item: CartItem) => {
    if (isItemInCart(item._id)) {
      return {
        success: false,
        message: "This item is already in your cart"
      };
    }

    setCartItems(prevItems => [...prevItems, { ...item, quantity: 1 }]);
    return {
      success: true,
      message: "Item added to cart successfully"
    };
  };

  const removeFromCart = (itemIndex: number) => {
    setCartItems(currentItems =>
      currentItems.filter((_, index) => index !== itemIndex)
    );
  };

  if (!mounted) {
    return null;
  }

  return (
    <CartContext.Provider value={{ cartItems, addToCart, removeFromCart, isItemInCart }}>
      {children}
    </CartContext.Provider>
  );
}

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};