//app/client-layout.tssx
'use client'

import { AuthProvider } from "../src/lib/AuthContext";
import { CartProvider } from "../src/contexts/CartContext";
import { GoogleAnalytics } from '@next/third-parties/google';
import { useEffect, useState } from 'react';

export function ClientLayout({
    children,
}: {
    children: React.ReactNode;
}) {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    if (!mounted) {
        return null;
    }

    return (
        <>
            <GoogleAnalytics gaId="G-L6K1F25D5F" />
            <AuthProvider>
                <CartProvider>
                    {children}
                </CartProvider>
            </AuthProvider>
        </>
    );
}